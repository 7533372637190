import Log from '$lib/utils/log';
import { analytics } from '$lib/utils/segment';
import type { HandleClientError } from '@sveltejs/kit';

export const handleError: HandleClientError = async ({ error }) => {
	try {
		const { anonymousId } = await analytics.user();

		Log.anonymousId = anonymousId() || '';
	} catch (e) {
		Log.scope('Hooks - Client').exception(e);
	}

	Log.scope('Hooks - Client').exception(error);
};
