import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,3,12,5,4,8,9,14,15,6,2];

export const dictionary = {
		"/(marketing)": [~17,[3]],
		"/(marketing)/app": [~21,[3]],
		"/blog": [~34,[12]],
		"/blog/[slug]": [~35,[12]],
		"/(marketing)/contact": [~22,[3]],
		"/(marketing)/faq/[[slug]]": [~23,[3]],
		"/(marketing)/food-recycler": [~24,[3,7]],
		"/(marketing)/(referral)/friends": [~19,[3,5]],
		"/(marketing)/(referral)/friend": [~18,[3,4]],
		"/(marketing)/impact": [~25,[3]],
		"/(marketing)/legal/open-source-software-disclosure": [27,[3,8]],
		"/(marketing)/legal/[[slug]]": [26,[3,8,9]],
		"/(marketing)/licenses": [28,[3]],
		"/lp/[slug]": [~36,[13]],
		"/news": [~37,[14]],
		"/news/[slug]": [~38,[14]],
		"/(marketing)/order-now": [~29,[3,10]],
		"/(marketing)/order-now/[orderPage]": [~30,[3,10]],
		"/order/success": [39,[15]],
		"/(marketing)/(referral)/refer": [20,[3,6]],
		"/(marketing)/reviews": [~31,[3,11]],
		"/(marketing)/system": [~32,[3]],
		"/(marketing)/team": [~33,[3]],
		"/(landingpage)/[slug]": [16,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';